import _implicitStylesheets from "./fileSecurity.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {d: api_dynamic, t: api_text, h: api_element, k: api_key, b: api_bind, i: api_iterator} = $api;
  const {_m0} = $ctx;
  return [api_element("label", {
    key: 0
  }, [api_dynamic($cmp.fileFields.length), api_text(" File Types:")]), $cmp.fileFields.length ? api_element("div", {
    classMap: {
      "infoMessage": true
    },
    key: 1
  }, [api_text("For optimal user experience when using the zPaper solution, please leave the selections as 'HYBRID'.")]) : null, $cmp.fileFields.length ? api_element("dl", {
    key: 2
  }, api_iterator($cmp.fileFields, function (type) {
    return [api_element("dt", {
      className: type.classRisky,
      key: api_key(3, type.fileType)
    }, [api_element("span", {
      key: 4
    }, [api_dynamic(type.fileType)]), !type.safeToExec ? api_element("span", {
      classMap: {
        "infoIcon": true
      },
      attrs: {
        "title": "Security Risk - Execute in Browser not allowed"
      },
      key: 5
    }, []) : null]), api_element("dd", {
      className: type.classBehavior,
      key: api_key(6, type.fileType)
    }, [api_element("select", {
      attrs: {
        "name": type.fileType,
        "value": type.behavior
      },
      key: 7,
      on: {
        "change": _m0 || ($ctx._m0 = api_bind($cmp.handleChange))
      }
    }, [api_element("option", {
      attrs: {
        "value": ""
      },
      key: 8
    }, [api_text("--default--")]), type.behaviorDownload ? api_element("option", {
      attrs: {
        "selected": ""
      },
      key: 9
    }, [api_text("DOWNLOAD")]) : null, !type.behaviorDownload ? api_element("option", {
      key: 10
    }, [api_text("DOWNLOAD")]) : null, type.safeToExec ? type.behaviorExecute ? api_element("option", {
      attrs: {
        "value": "EXECUTE_IN_BROWSER",
        "selected": ""
      },
      key: 11
    }, [api_text("EXECUTE")]) : null : null, type.safeToExec ? !type.behaviorExecute ? api_element("option", {
      attrs: {
        "value": "EXECUTE_IN_BROWSER"
      },
      key: 12
    }, [api_text("EXECUTE")]) : null : null, type.behaviorHybrid ? api_element("option", {
      attrs: {
        "selected": ""
      },
      key: 13
    }, [api_text("HYBRID")]) : null, !type.behaviorHybrid ? api_element("option", {
      key: 14
    }, [api_text("HYBRID")]) : null])])];
  })) : null];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_fileSecurity-host",
  shadowAttribute: "my-modules-m2o_fileSecurity"
};
