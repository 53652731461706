const utils = require("../utils");

const deployAssetsURL = '/api/deploy-assets';
const activateFlexiPagesURL = '/api/activate-flexipages';
const installedPackagesURL = '/api/installed-packages';
const groupQueuesURL = '/api/group-queues';
const objectTypesURL = '/api/object-types';
const recordTypesURL = '/api/record-types';
const customSettingsURL = '/api/custom-settings';
const autodriveSettingsURL = '/api/autodrive-settings';
const networkAccessURL = '/api/network-access';
const remoteSitesURL = '/api/remote-sites';
const trustedSitesURL = '/api/trusted-sites';
const fileTypesURL = '/api/file-types';
const connectedAppSettingsURL = '/api/connected-app-settings';
export const deployAssets = assetsUrl => {
  return utils.postApi(deployAssetsURL, assetsUrl);
};
export const activateFlexiPages = () => {
  return utils.postApi(activateFlexiPagesURL);
};
export const getInstalledPackages = () => {
  return utils.fetchApi(installedPackagesURL);
};
export const getGroupQueues = () => {
  return utils.fetchApi(groupQueuesURL);
};
export const getObjectTypes = () => {
  return utils.fetchApi(objectTypesURL);
};
export const getRecordTypes = () => {
  return utils.fetchApi(recordTypesURL);
};
export const getCustomSettings = () => {
  return utils.fetchApi(customSettingsURL);
};
export const saveCustomSettings = settings => {
  return utils.postApi(customSettingsURL, settings);
};
export const getAutodriveSettings = () => {
  return utils.fetchApi(autodriveSettingsURL);
};
export const getNetworkAccess = () => {
  return utils.fetchApi(networkAccessURL);
};
export const saveNetworkAccess = access => {
  return utils.postApi(networkAccessURL, access);
};
export const getRemoteSites = zpServer => {
  return utils.fetchApi(`${remoteSitesURL}?server=${zpServer}`);
};
export const saveRemoteSites = remotes => {
  return utils.postApi(remoteSitesURL, remotes);
};
export const getTrustedSites = zpServer => {
  return utils.fetchApi(`${trustedSitesURL}?server=${zpServer}`);
};
export const saveTrustedSites = trusted => {
  return utils.postApi(trustedSitesURL, trusted);
};
export const getFileTypes = () => {
  return utils.fetchApi(fileTypesURL);
};
export const saveFileTypes = types => {
  return utils.postApi(fileTypesURL, types);
};
export const getConnectedAppSettings = appName => {
  return utils.fetchApi(`${connectedAppSettingsURL}?appName=${appName}`);
};
export const addProfilesToConnectedApp = profiles => {
  return utils.postApi(connectedAppSettingsURL, profiles);
};