import _implicitStylesheets from "./registerOrganization.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {gid: api_scoped_id, h: api_element, k: api_key, d: api_dynamic, t: api_text, b: api_bind, i: api_iterator, f: api_flatten} = $api;
  const {_m0, _m1, _m2} = $ctx;
  return [$cmp.deploymentExists ? api_element("div", {
    attrs: {
      "id": api_scoped_id("verify-organization")
    },
    key: 0
  }, []) : null, !$cmp.deploymentExists ? api_element("form", {
    attrs: {
      "action": "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
      "method": "POST"
    },
    key: 1
  }, api_iterator($cmp.registerFields, function (field) {
    return api_element("div", {
      classMap: {
        "form-group": true
      },
      key: api_key(2, field.key)
    }, [field.label ? api_element("label", {
      key: 3
    }, [api_dynamic(field.label), api_text(":")]) : null, !field.selector ? field.readonly ? api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "data-fld-name": field.key,
        "name": field.name,
        "type": field.type,
        "placeholder": field.default,
        "readonly": ""
      },
      props: {
        "value": field.value
      },
      key: 4
    }, []) : null : null, !field.selector ? !field.readonly ? api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "data-fld-name": field.key,
        "name": field.name,
        "type": field.type,
        "placeholder": field.default
      },
      props: {
        "value": field.value
      },
      key: 5,
      on: {
        "input": _m0 || ($ctx._m0 = api_bind($cmp.handleKeypress)),
        "change": _m1 || ($ctx._m1 = api_bind($cmp.handleChange))
      }
    }, []) : null : null, field.selector ? api_element("select", {
      attrs: {
        "name": field.name,
        "value": field.value
      },
      key: 6,
      on: {
        "change": _m2 || ($ctx._m2 = api_bind($cmp.handleChange))
      }
    }, api_flatten([api_element("option", {
      attrs: {
        "value": ""
      },
      key: 7
    }, [api_text("-- none --")]), api_iterator(field.options, function (option) {
      return api_element("option", {
        key: api_key(8, option)
      }, [api_dynamic(option)]);
    })])) : null]);
  })) : null, !$cmp.deploymentExists ? api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 9
  }, [api_text("If this information is not correct, please  "), api_element("a", {
    attrs: {
      "href": "/oauth/logout"
    },
    key: 10
  }, [api_text("logout")]), api_text(" of this app and then authorize with the correct instance of Salesforce.")]) : null, $cmp.isRegistered ? api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 11
  }, [api_text("This Salesforce Org was previously registered for zPaper Document Merge.")]) : null, !$cmp.isRegistered ? api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 12
  }, [api_text("Upon submitting this information, your Salesforce Org will have access to the zPaper Document Merge utilities.")]) : null, $cmp.missingPackage ? api_element("div", {
    classMap: {
      "warningMessage": true
    },
    key: 13
  }, [api_element("span", {
    classMap: {
      "warning": true
    },
    key: 14
  }, [api_text("WARNING: ")]), api_text("The target org is not enabled for "), api_dynamic($cmp.missingPackage), api_text("!"), api_element("br", {
    key: 15
  }, []), api_text("This zPaper installation requires the sObject type ReceivedDocument"), api_element("br", {
    key: 16
  }, []), api_text("to be installed in order to work correctly.")]) : null];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_registerOrganization-host",
  shadowAttribute: "my-modules-m2o_registerOrganization"
};
