import _implicitStylesheets from "./zpaperSolutions.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, h: api_element, d: api_dynamic, k: api_key, b: api_bind, gid: api_scoped_id, i: api_iterator, f: api_flatten} = $api;
  const {_m0, _m1, _m2, _m3} = $ctx;
  return [$cmp.missingPackage ? api_element("div", {
    classMap: {
      "warningMessage": true
    },
    key: 0
  }, [api_element("span", {
    classMap: {
      "warning": true
    },
    key: 1
  }, [api_text("WARNING: ")]), api_text("The target org is missing a required package: "), api_dynamic($cmp.missingPackage), api_text("!"), api_element("br", {
    key: 2
  }, []), !$cmp.allowMissingPackage ? api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 3
  }, [api_text("The package must be available in you Org before installing zPaper:Transport can proceed."), api_element("br", {
    key: 4
  }, []), api_text("Please try again later, after the missing package has been installed...")]) : null]) : null, api_element("label", {
    key: 5
  }, [api_dynamic($cmp.solutions.length), api_text(" Accelerator(s) Available:")]), $cmp.solutions.length ? api_element("table", {
    key: 6
  }, [api_element("tbody", {
    key: 7
  }, api_flatten([api_iterator($cmp.solutions, function (solution) {
    return [api_element("tr", {
      key: api_key(8, solution.id)
    }, [api_element("td", {
      styleMap: {
        "width": "10px",
        "padding": "auto"
      },
      key: 9
    }, [!$cmp.solutionName ? api_element("input", {
      attrs: {
        "type": "radio",
        "name": "zSolution",
        "data-solution-id": solution.id
      },
      props: {
        "value": solution.name
      },
      key: 10,
      on: {
        "click": _m0 || ($ctx._m0 = api_bind($cmp.handleClick))
      }
    }, []) : null, $cmp.solutionName ? api_element("input", {
      attrs: {
        "type": "radio",
        "name": "zSolution",
        "data-solution-id": solution.id,
        "disabled": ""
      },
      props: {
        "value": solution.name,
        "checked": true
      },
      key: 11
    }, []) : null]), api_element("td", {
      styleMap: {
        "width": "40%"
      },
      attrs: {
        "colspan": "2"
      },
      key: 12
    }, [api_element("label", {
      classMap: {
        "solution-name": true
      },
      key: 13
    }, [api_text(" "), api_dynamic(solution.name)]), api_element("span", {
      classMap: {
        "infoIcon": true
      },
      attrs: {
        "id": api_scoped_id(solution.helpId),
        "title": "Show Description...",
        "data-solution-id": solution.id
      },
      key: 14,
      on: {
        "click": _m1 || ($ctx._m1 = api_bind($cmp.showDescription))
      }
    }, []), api_element("div", {
      styleMap: {
        "font-size": "smaller",
        "text-align": "center"
      },
      key: 15
    }, [api_dynamic(solution.builddate)])]), api_element("td", {
      styleMap: {
        "width": "auto",
        "padding": "0 20px"
      },
      attrs: {
        "rowspan": "2"
      },
      key: 16
    }, [api_element("ol", {
      attrs: {
        "name": solution.id
      },
      key: 17
    }, [solution.package.assets ? !$cmp.isDeployed ? api_element("li", {
      classMap: {
        "install-assets": true,
        "step-pending": true
      },
      attrs: {
        "title": solution.package.assets
      },
      key: 18
    }, [api_text(" Deploy zPaper Components to Salesforce")]) : null : null, solution.package.assets ? $cmp.isDeployed ? api_element("li", {
      classMap: {
        "install-assets": true,
        "step-success": true
      },
      attrs: {
        "title": solution.package.assets
      },
      key: 19
    }, [api_text(" Re-Deploy zPaper Components to Salesforce?")]) : null : null, solution.package.templates ? api_element("li", {
      classMap: {
        "install-templates": true,
        "step-pending": true
      },
      attrs: {
        "title": solution.package.templates
      },
      key: 20
    }, [api_text(" Import solution Templates into zPaper")]) : null, solution.package.importUrl ? api_element("li", {
      classMap: {
        "install-extras": true,
        "step-pending": true
      },
      attrs: {
        "title": solution.package.importUrl
      },
      key: 21
    }, [api_text(" Post-Install processing step(s)")]) : null, solution.package.configUrl ? !$cmp.missingModule ? api_element("li", {
      classMap: {
        "install-config": true
      },
      attrs: {
        "title": solution.package.configUrl
      },
      key: 22
    }, [api_text(" Configure any Custom Settings for this Accelerator..."), api_element("div", {
      classMap: {
        "next-step-icon": true
      },
      attrs: {
        "title": "Jump to the next step without re-installing",
        "data-solution-id": solution.id
      },
      key: 23,
      on: {
        "click": _m2 || ($ctx._m2 = api_bind($cmp.showConfigStep))
      }
    }, [])]) : null : null])])]), api_element("tr", {
      key: api_key(24, solution.id)
    }, [api_element("td", {
      key: 25
    }, [api_text(" ")]), api_element("td", {
      styleMap: {
        "width": "40%"
      },
      attrs: {
        "colspan": "2"
      },
      key: 26
    }, [api_element("div", {
      styleMap: {
        "font-size": "smaller",
        "overflow": "hidden",
        "width": "400px",
        "height": "60px"
      },
      attrs: {
        "name": solution.id
      },
      key: 27
    }, [api_element("div", {
      classMap: {
        "more-info": true
      },
      context: {
        lwc: {
          dom: "manual"
        }
      },
      key: 28
    }, [])])])]), api_element("tr", {
      classMap: {
        "row-separator": true
      },
      key: api_key(29, solution.id)
    }, [api_element("td", {
      attrs: {
        "colspan": "3"
      },
      key: 30
    }, [])])];
  }), !$cmp.solutionName ? api_element("tr", {
    key: 31
  }, [api_element("td", {
    key: 32
  }, [api_element("input", {
    attrs: {
      "type": "radio",
      "name": "zSolution",
      "data-solution-id": ""
    },
    props: {
      "value": "",
      "checked": true
    },
    key: 33,
    on: {
      "click": _m3 || ($ctx._m3 = api_bind($cmp.handleClick))
    }
  }, [])]), api_element("td", {
    attrs: {
      "colspan": "2"
    },
    key: 34
  }, [api_element("label", {
    classMap: {
      "solutions-label": true
    },
    key: 35
  }, [api_text(" NONE (Skip this step...)")])])]) : null]))]) : null];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_zpaperSolutions-host",
  shadowAttribute: "my-modules-m2o_zpaperSolutions"
};
