import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./remoteSites.html";
import zLightningElement from 'base/zLightningElement';
import { getRemoteSites, saveRemoteSites } from 'data/salesforceConfigService';

class RemoteSites extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.remoteSites = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.isDirty = true; // Only save these fixed values the first time
    // eslint-disable-next-line @lwc/lwc/no-document-query

    let verifyOrg = document.querySelector("verify-organization");
    let deployment = verifyOrg && verifyOrg.deploymentRecord;

    if (deployment && deployment.Server__c) {
      getRemoteSites(deployment.Server__c).then(result => {
        this.remoteSites = result;
      }).catch(error => {
        console.error(error);
      });
    } else {
      console.error("Deployment Record is missing the 'Server__c' hostname!");
    }
  }

  async saveYourself() {
    if (!this.isDirty) {
      this.completed = true;
      return;
    }

    await saveRemoteSites(this.remoteSites).then(results => {
      this.completed = true;
      this.isDirty = false;
      return results;
    }).catch(errors => {
      // eslint-disable-next-line no-alert
      alert(errors);
      return errors;
    });
  }

}

_registerDecorators(RemoteSites, {
  track: {
    remoteSites: 1
  }
})

export default _registerComponent(RemoteSites, {
  tmpl: _tmpl
});