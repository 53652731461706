import _implicitStylesheets from "./loginView.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {h: api_element, t: api_text, d: api_dynamic, gid: api_scoped_id, b: api_bind, fid: api_scoped_frag_id} = $api;
  const {_m0} = $ctx;
  return [api_element("header", {
    key: 0
  }, [api_element("img", {
    attrs: {
      "src": "/resources/zpaper-logo-only.png",
      "alt": "zPaper logo"
    },
    key: 1
  }, []), api_element("h2", {
    key: 2
  }, [api_text(" "), api_dynamic($cmp.marquee), api_text(" ")]), api_element("div", {
    classMap: {
      "actions": true
    },
    key: 3
  }, [api_element("h4", {
    classMap: {
      "package-name": true
    },
    key: 4
  }, [api_dynamic($cmp.packageName)]), $cmp.loggedUser ? api_element("span", {
    classMap: {
      "user-display-name": true
    },
    key: 5
  }, [api_dynamic($cmp.loggedUser.display_name)]) : null, $cmp.loggedUser ? api_element("br", {
    key: 6
  }, []) : null, $cmp.loggedUser ? api_element("span", {
    classMap: {
      "user-domain-name": true
    },
    key: 7
  }, [api_dynamic($cmp.loggedUser.urls.custom_domain)]) : null, $cmp.loggedUser ? api_element("br", {
    key: 8
  }, []) : null, $cmp.loggedUser ? api_element("a", {
    attrs: {
      "href": "/oauth/logout"
    },
    key: 9
  }, [api_element("button", {
    classMap: {
      "brand": true
    },
    attrs: {
      "type": "button"
    },
    key: 10
  }, [api_text("Log Out")])]) : null])]), api_element("main", {
    classMap: {
      "content": true
    },
    key: 11
  }, [!$cmp.loggedUser ? api_element("div", {
    classMap: {
      "login-wrapper": true
    },
    key: 12
  }, [api_element("div", {
    classMap: {
      "login": true
    },
    key: 13
  }, [api_element("h2", {
    key: 14
  }, [api_text("Welcome, please log in with your Salesforce account")]), api_element("div", {
    key: 15
  }, [api_element("div", {
    key: 16
  }, [api_element("input", {
    classMap: {
      "brand": true
    },
    attrs: {
      "id": api_scoped_id("use-sandbox"),
      "type": "checkbox"
    },
    props: {
      "value": $cmp.isSandbox
    },
    key: 17,
    on: {
      "change": _m0 || ($ctx._m0 = api_bind($cmp.useSandbox))
    }
  }, []), api_element("label", {
    attrs: {
      "for": `${api_scoped_id("use-sandbox")}`
    },
    key: 18
  }, [api_text("This is a Sandbox Org")]), api_element("div", {
    classMap: {
      "infoIcon": true
    },
    attrs: {
      "id": api_scoped_id("loginToSandbox")
    },
    key: 19
  }, [])]), api_element("a", {
    classMap: {
      "brand-button": true
    },
    attrs: {
      "href": api_scoped_frag_id($cmp.loginUrl)
    },
    key: 20
  }, [api_text("Log In")])])])]) : null])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_loginView-host",
  shadowAttribute: "my-modules-m2o_loginView"
};
