import _implicitStylesheets from "./consentAgreement.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, h: api_element, gid: api_scoped_id, b: api_bind} = $api;
  const {_m0, _m1} = $ctx;
  return [api_element("div", {
    classMap: {
      "container": true
    },
    key: 0
  }, [api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 1
  }, [api_text("Please review the following agreement before proceeding with the installation...")]), api_element("div", {
    classMap: {
      "form-group": true
    },
    key: 2
  }, [api_element("iframe", {
    attrs: {
      "src": $cmp.documentUrl
    },
    key: 3
  }, [])]), api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 4
  }, [api_element("label", {
    classMap: {
      "agree-text": true
    },
    attrs: {
      "for": `${api_scoped_id("signMeUp")}`
    },
    key: 5
  }, [api_text("I have read and understood this contract, and agree to the terms and conditions contained therein ⇒")]), $cmp.signedAgreement ? api_element("input", {
    classMap: {
      "form-control": true
    },
    attrs: {
      "type": "checkbox"
    },
    props: {
      "value": "true",
      "checked": true
    },
    key: 6,
    on: {
      "change": _m0 || ($ctx._m0 = api_bind($cmp.handleCheckbox))
    }
  }, []) : null, !$cmp.signedAgreement ? api_element("input", {
    classMap: {
      "form-control": true
    },
    attrs: {
      "type": "checkbox"
    },
    props: {
      "value": "false"
    },
    key: 7,
    on: {
      "change": _m1 || ($ctx._m1 = api_bind($cmp.handleCheckbox))
    }
  }, []) : null])])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_consentAgreement-host",
  shadowAttribute: "my-modules-m2o_consentAgreement"
};
