import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./autodriveConfig.html";

/* eslint-disable dot-notation */
import zLightningElement from 'base/zLightningElement';
import { getDeploymentRecord, getAutodriveForms, importTemplates, clearRedisCache } from 'data/zpaperConfigService';
import { getCustomSettings, saveCustomSettings } from 'data/salesforceConfigService';

class AutodriveConfig extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.deploymentRecord = {};
    this.autodriveSettings = {};
    this.autodriveForms = [];
    this.settingsHashmap = {};
    this.updatedSettings = {};
  }

  connectedCallback() {
    super.connectedCallback(); // eslint-disable-next-line @lwc/lwc/no-document-query

    let loginView = document.querySelector('login-view');
    let loggedUser = loginView && loginView.loggedUser;

    if (!loggedUser) {
      console.error('User is not logged in!');
      return;
    }

    getCustomSettings().then(settings => {
      // preserve the Custom Settings object as a flat object that contains name/value pairs
      Object.keys(settings).forEach(key => {
        this.settingsHashmap[key] = settings[key].value;
      }); // Flatten/Filter the map into an array that can be rendered in the template...

      this.autodriveSettings = Object.keys(settings).filter(key => key === 'Id' || key.startsWith('AD')).map(field => settings[field]);
    }).catch(error => {
      console.error(error);
    });
    getDeploymentRecord(loggedUser.organization_id).then(deployment => {
      if (deployment.errorMessage) {
        throw new Error(deployment.errorMessage);
      } // eslint-disable-next-line @lwc/lwc/no-api-reassignments


      this.sfServer = loggedUser.urls.custom_domain || loggedUser.urls.rest; // eslint-disable-next-line @lwc/lwc/no-api-reassignments

      this.sfServer = this.sfServer.split(/[/]+/)[1];
      console.debug("this.sfServer =>", this.sfServer); // eslint-disable-next-line @lwc/lwc/no-api-reassignments

      this.deploymentRecord = deployment;
      getAutodriveForms().then(forms => {
        let adForms = forms || [];
        adForms.forEach(adForm => {
          // check for zippi v1.9 fields
          if (adForm.Id) {
            adForm.id = adForm.Id;
            adForm.bates = adForm.BATES;
          }

          adForm.formName = `${adForm.metadata.X_docType}:${adForm.bates}`;
          adForm.imgSrc = `/api/form-image?formId=${adForm.id}`;
        });
        this.autodriveForms = adForms; // Manually update the checkboxes to match the settings (for now)

        let form = this.template.querySelector('form'); // eslint-disable-next-line @lwc/lwc/no-async-operation

        setTimeout(() => {
          this.checkBoxes(form);
        }, 2000);
      }).catch(error => {
        console.error(error); // eslint-disable-next-line no-alert

        alert(error);
      }); // })
      // .catch(error => {
      //     console.error("Error received when retrieving Deployment record!");
      //     console.error(error);
    });
  }

  importTemplates(evt) {
    let zipUrl = evt.target.previousElementSibling.value;

    if (zipUrl && this.apiKey) {
      importTemplates(zipUrl, this.apiKey);
    }
  }

  checkBoxes(form) {
    // eslint-disable-next-line no-undef
    let $form = $(form); // Set the selected options for confidence values (older data may be in the range 0.0 - 1.0)

    let tempConf = this.settingsHashmap.ADtemplateConfidence__c || 90;
    $form.find('select[name="ADtemplateConfidence__c"]')[0].value = tempConf < 1 ? (100 * tempConf).toFixed(0) : tempConf;
    let persConf = this.settingsHashmap.ADpersonConfidence__c || 90;
    $form.find('select[name="ADpersonConfidence__c"]')[0].value = persConf < 1 ? (100 * persConf).toFixed(0) : persConf;
    let dataConf = this.settingsHashmap.ADdataConfidence__c || 90;
    $form.find('select[name="ADdataConfidence__c"]')[0].value = dataConf < 1 ? (100 * dataConf).toFixed(0) : dataConf; // Find and check all the active forms by name

    let formNames = (this.settingsHashmap.ADtemplates__c || '').split(',');

    for (let $box of $form.find("h3 input")) {
      $box.checked = formNames.includes($box.name);
    } // Then find and check the other options (per form)


    let formTypes = (this.settingsHashmap.ADrotated__c || '').split(',');

    for (let $box of $form.find("input[name='rotated']")) {
      // eslint-disable-next-line no-unused-vars
      let [type, bates] = $box.value.split(':');
      $box.checked = formTypes.includes(type);
    }

    formTypes = (this.settingsHashmap.ADsignatures__c || '').split(',');

    for (let $box of $form.find("input[name='signed']")) {
      // eslint-disable-next-line no-unused-vars
      let [type, bates] = $box.value.split(':');
      $box.checked = formTypes.includes(type);
    }

    formTypes = (this.settingsHashmap.ADpersonTypes__c || '').split(',');

    for (let $box of $form.find("input[name='person']")) {
      // eslint-disable-next-line no-unused-vars
      let [type, bates] = $box.value.split(':');
      $box.checked = formTypes.includes(type);
    }

    formTypes = (this.settingsHashmap.ADdata__c || '').split(',');

    for (let $box of $form.find("input[name='field']")) {
      // eslint-disable-next-line no-unused-vars
      let [type, bates] = $box.value.split(':');
      $box.checked = formTypes.includes(type);
    }
  }

  handleSave(evt) {
    let form = evt.target.form;

    if (form) {
      // eslint-disable-next-line no-undef
      let adForm = $(evt.target.form);
      let adTemplates = [];
      let adForms = adForm.find("h3 input:checked");

      for (let ii = 0; ii < adForms.length; ii++) {
        adTemplates.push(adForms[ii].name);
      }

      let adRotated = [];
      adForms = adForm.find("input[name='rotated']:checked");

      for (let ii = 0; ii < adForms.length; ii++) {
        // eslint-disable-next-line no-unused-vars
        let [type, bates] = adForms[ii].value.split(':');
        adRotated.push(type);
      }

      let adSignatures = [];
      adForms = adForm.find("input[name='signed']:checked");

      for (let ii = 0; ii < adForms.length; ii++) {
        // eslint-disable-next-line no-unused-vars
        let [type, bates] = adForms[ii].value.split(':');
        adSignatures.push(type);
      }

      let adPersonTypes = [];
      adForms = adForm.find("input[name='person']:checked");

      for (let ii = 0; ii < adForms.length; ii++) {
        // eslint-disable-next-line no-unused-vars
        let [type, bates] = adForms[ii].value.split(':');
        adPersonTypes.push(type);
      }

      let adDataFields = [];
      adForms = adForm.find("input[name='field']:checked");

      for (let ii = 0; ii < adForms.length; ii++) {
        // eslint-disable-next-line no-unused-vars
        let [type, bates] = adForms[ii].value.split(':');
        adDataFields.push(type);
      }

      let adTemplateConfidence = adForm.find("select[name='ADtemplateConfidence__c'] option:selected")[0].value || 0;
      let adPersonConfidence = adForm.find("select[name='ADpersonConfidence__c'] option:selected")[0].value || 0;
      let adDataConfidence = adForm.find("select[name='ADdataConfidence__c'] option:selected")[0].value || 0;
      this.updatedSettings = {
        Id: this.settingsHashmap.Id,
        ADtemplates__c: adTemplates.join(','),
        // ADrotated__c: ',' + adRotated.join(',') + ',',
        ADsignatures__c: ',' + adSignatures.join(',') + ',',
        ADpersonTypes__c: ',' + adPersonTypes.join(',') + ',',
        ADdata__c: ',' + adDataFields.join(',') + ',',
        ADtemplateConfidence__c: adTemplateConfidence,
        ADpersonConfidence__c: adPersonConfidence,
        ADdataConfidence__c: adDataConfidence
      };
      this.isDirty = true;
      saveCustomSettings(this.updatedSettings).then(response => {
        if (response && response.errorCode) {
          throw new Error(response.errorCode);
        }

        if (response && response.errors && response.errors.length) {
          throw new Error(response.errors.join('\n'));
        }

        console.info({
          customSettings: response
        });
        this.updatedSettings = {};
        this.isDirty = false;
        clearRedisCache().then(reset => {
          if (reset.status !== 'success') {
            throw new Error(JSON.stringify(reset));
          }
        }).catch(error => {
          console.warn("Custom Settings saved, but the Redis cache was not reset!");
          console.error(error);
        });
      }).catch(errors => {
        console.error(errors); // eslint-disable-next-line no-alert

        alert(`Error(s) in saving AD Settings:\n ${errors}`);
      });
    }
  }

}

_registerDecorators(AutodriveConfig, {
  track: {
    deploymentRecord: 1,
    autodriveSettings: 1,
    autodriveForms: 1
  },
  fields: ["settingsHashmap", "updatedSettings"]
})

export default _registerComponent(AutodriveConfig, {
  tmpl: _tmpl
});