import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./networkAccess.html";
import zLightningElement from 'base/zLightningElement';
import { getNetworkAccess, saveNetworkAccess } from 'data/salesforceConfigService';

class NetworkAccess extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.centerName = '';
    this.networkAccess = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.isDirty = true; // Only save these fixed values the first time

    getNetworkAccess().then(result => {
      let netAccess = result.map(ele => {
        ele.start = ele.start.join(',');
        ele.end = ele.end.join(',');
        ele.description = ele.description.join(',');
        return ele;
      }); // Filter to return only sites that are in my DataCenter (for transport/merge)

      if (this.centerName) {
        netAccess = netAccess.filter(dc => dc.description.startsWith(this.centerName) || dc.description === 'zPaper Installers'); //SHR211022 allow install host ip
      }

      this.networkAccess = netAccess;
    }).catch(error => {
      console.error(error);
    });
  }

  async saveYourself() {
    if (!this.isDirty) {
      this.completed = true;
      return;
    }

    await saveNetworkAccess(this.networkAccess).then(results => {
      this.completed = true;
      this.isDirty = false;
      return results;
    }).catch(errors => {
      // eslint-disable-next-line no-alert
      alert(errors);
      return errors;
    });
  }

}

_registerDecorators(NetworkAccess, {
  publicProps: {
    centerName: {
      config: 0
    }
  },
  track: {
    networkAccess: 1
  }
})

export default _registerComponent(NetworkAccess, {
  tmpl: _tmpl
});