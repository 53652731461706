import _implicitStylesheets from "./remoteSites.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {d: api_dynamic, t: api_text, h: api_element, k: api_key, i: api_iterator} = $api;
  return [api_element("label", {
    key: 0
  }, [api_dynamic($cmp.remoteSites.length), api_text(" Remote Sites:")]), $cmp.remoteSites.length ? api_element("dl", {
    key: 1
  }, api_iterator($cmp.remoteSites, function (site) {
    return [api_element("dt", {
      classMap: {
        "active-{IsActive}": true
      },
      key: api_key(2, site.id)
    }, [api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "value": site.id,
        "checked": true
      },
      key: 3
    }, []), api_dynamic(site.fullName)]), api_element("dd", {
      key: api_key(4, site.id)
    }, [api_dynamic(site.url)])];
  })) : null];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_remoteSites-host",
  shadowAttribute: "my-modules-m2o_remoteSites"
};
