import _implicitStylesheets from "./autodriveConfig.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, h: api_element, gid: api_scoped_id, b: api_bind, k: api_key, d: api_dynamic, i: api_iterator, f: api_flatten} = $api;
  const {_m0, _m1, _m2} = $ctx;
  return [api_element("h3", {
    key: 0
  }, [api_text("AutoDrive® Configuration")]), api_element("form", {
    attrs: {
      "id": api_scoped_id("config-form")
    },
    key: 1
  }, api_flatten([api_element("input", {
    styleMap: {
      "float": "right",
      "margin": "10px"
    },
    attrs: {
      "type": "button"
    },
    props: {
      "value": "Save Settings"
    },
    key: 2,
    on: {
      "click": _m0 || ($ctx._m0 = api_bind($cmp.handleSave))
    }
  }, []), api_element("ul", {
    key: 3
  }, [api_element("li", {
    key: 4
  }, [api_text("Install Template "), api_element("a", {
    attrs: {
      "href": "https://zpaper.my.salesforce.com/a1D0g0000064DWK",
      "target": "zO2O"
    },
    key: 5
  }, [api_text("Accelerator")]), api_element("select", {
    key: 6
  }, [api_element("option", {
    attrs: {
      "value": "https://edge.zpaper.com/kb/books/zpaper.com.-20200._20200.202002291553.wdz.zip"
    },
    key: 7
  }, [api_text("PAP")]), api_element("option", {
    attrs: {
      "value": ""
    },
    key: 8
  }, [api_text("Payroll")]), api_element("option", {
    attrs: {
      "value": ""
    },
    key: 9
  }, [api_text("Referrals")]), api_element("option", {
    attrs: {
      "value": ""
    },
    key: 10
  }, [api_text("Members")])]), api_element("input", {
    attrs: {
      "type": "button"
    },
    props: {
      "value": "Load"
    },
    key: 11,
    on: {
      "click": _m1 || ($ctx._m1 = api_bind($cmp.importTemplates))
    }
  }, [])]), api_element("li", {
    key: 12
  }, [api_text("Automation Confidence")]), api_element("ul", {
    key: 13
  }, [api_element("li", {
    key: 14
  }, [api_element("select", {
    attrs: {
      "name": "ADtemplateConfidence__c"
    },
    key: 15
  }, [api_element("option", {
    attrs: {
      "value": "90"
    },
    key: 16
  }, [api_text("90%")]), api_element("option", {
    attrs: {
      "value": "80"
    },
    key: 17
  }, [api_text("80%")]), api_element("option", {
    attrs: {
      "value": "70"
    },
    key: 18
  }, [api_text("70%")])]), api_text("Template Detection")]), api_element("li", {
    key: 19
  }, [api_element("select", {
    attrs: {
      "name": "ADpersonConfidence__c"
    },
    key: 20
  }, [api_element("option", {
    attrs: {
      "value": "99"
    },
    key: 21
  }, [api_text("99%")]), api_element("option", {
    attrs: {
      "value": "90"
    },
    key: 22
  }, [api_text("90%")]), api_element("option", {
    attrs: {
      "value": "80"
    },
    key: 23
  }, [api_text("80%")]), api_element("option", {
    attrs: {
      "value": "70"
    },
    key: 24
  }, [api_text("70%")])]), api_text("Person Detection")]), api_element("li", {
    key: 25
  }, [api_element("select", {
    attrs: {
      "name": "ADdataConfidence__c"
    },
    key: 26
  }, [api_element("option", {
    attrs: {
      "value": "90"
    },
    key: 27
  }, [api_text("90%")]), api_element("option", {
    attrs: {
      "value": "80"
    },
    key: 28
  }, [api_text("80%")]), api_element("option", {
    attrs: {
      "value": "70"
    },
    key: 29
  }, [api_text("70%")]), api_element("option", {
    attrs: {
      "value": "50"
    },
    key: 30
  }, [api_text("50%")])]), api_text("Value Detection")])])]), api_iterator($cmp.autodriveForms, function (adForm) {
    return api_element("div", {
      classMap: {
        "form-box": true
      },
      key: api_key(31, adForm.id)
    }, [api_element("h3", {
      key: 32
    }, [api_element("input", {
      attrs: {
        "type": "checkbox",
        "name": adForm.formName
      },
      props: {
        "value": adForm.id
      },
      key: 33
    }, []), api_dynamic(adForm.metadata.X_docType), api_text(": "), api_dynamic(adForm.bates)]), api_element("div", {
      classMap: {
        "image-box": true
      },
      key: 34
    }, [api_element("img", {
      classMap: {
        "form-image": true
      },
      attrs: {
        "src": adForm.imgSrc
      },
      key: 35
    }, [])]), api_element("div", {
      classMap: {
        "option-box": true
      },
      key: 36
    }, [api_element("div", {
      classMap: {
        "option-row": true
      },
      key: 37
    }, [api_element("input", {
      attrs: {
        "name": "rotated",
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "value": adForm.formName
      },
      key: 38
    }, []), api_text("Rotated")]), api_element("div", {
      classMap: {
        "option-row": true
      },
      key: 39
    }, [api_element("input", {
      attrs: {
        "name": "signed",
        "type": "checkbox"
      },
      props: {
        "value": adForm.formName
      },
      key: 40
    }, []), api_text("Signed")]), api_element("div", {
      classMap: {
        "option-row": true
      },
      key: 41
    }, [api_element("input", {
      attrs: {
        "name": "person",
        "type": "checkbox"
      },
      props: {
        "value": adForm.formName
      },
      key: 42
    }, []), api_text("Person"), api_element("input", {
      attrs: {
        "type": "button"
      },
      props: {
        "value": "Lookup"
      },
      key: 43
    }, [])]), api_element("div", {
      classMap: {
        "option-row": true
      },
      key: 44
    }, [api_element("input", {
      attrs: {
        "name": "field",
        "type": "checkbox"
      },
      props: {
        "value": adForm.formName
      },
      key: 45
    }, []), api_text("Field"), api_element("sup", {
      key: 46
    }, [api_text("*")]), api_element("input", {
      attrs: {
        "type": "button"
      },
      props: {
        "value": "Data"
      },
      key: 47
    }, [])])])]);
  }), api_element("i", {
    styleMap: {
      "display": "block",
      "text-align": "center"
    },
    key: 48
  }, [api_text("* - handwriting field value detection at contracted rates")]), api_element("input", {
    styleMap: {
      "float": "right",
      "margin": "10px"
    },
    attrs: {
      "type": "button"
    },
    props: {
      "value": "Save Settings"
    },
    key: 49,
    on: {
      "click": _m2 || ($ctx._m2 = api_bind($cmp.handleSave))
    }
  }, [])]))];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_autodriveConfig-host",
  shadowAttribute: "my-modules-m2o_autodriveConfig"
};
