import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./fileSecurity.html";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import zLightningElement from 'base/zLightningElement';
import { getFileTypes, saveFileTypes } from 'data/salesforceConfigService';

class FileSecurity extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.fileTypes = [];
    this.fileFields = [];
  }

  connectedCallback() {
    super.connectedCallback();
    let requiredTypes = ['HTML', 'PDF']; // TODO: move this into the default config.json file

    getFileTypes().then(result => {
      let fileFields = result.filter(type => requiredTypes.includes(type.fileType)).map(type => {
        type.behavior = "HYBRID"; // always set to HYBRID

        return _objectSpread({
          safeToExec: type.securityRiskFileType === "false",
          // coerce to boolean
          classRisky: "risky-" + type.securityRiskFileType,
          classBehavior: "behavior-" + type.behavior,
          behaviorDownload: type.behavior === "DOWNLOAD",
          behaviorExecute: type.behavior === "EXECUTE_IN_BROWSER",
          behaviorHybrid: type.behavior === "HYBRID"
        }, type);
      });
      console.debug({
        fileFields
      });
      this.fileTypes = result;
      this.fileFields = fileFields;
    }).catch(error => {
      console.error(error);
    });
  }

  async saveYourself() {
    if (!this.isDirty) {
      this.completed = true;
      return;
    } // Sending ALL types... send just the filtered list?


    await saveFileTypes(this.fileTypes).then(results => {
      this.completed = true;
      this.isDirty = false;
      return results;
    }).catch(errors => {
      // eslint-disable-next-line no-alert
      alert(errors);
      return errors;
    });
  }

  handleChange(evt) {
    let eleName = evt.target.name;
    let eleValue = evt.target.value;
    this.fileTypes = this.fileTypes.map(type => {
      if (type.fileType === eleName) {
        type.behavior = eleValue;
      }

      return type;
    });
    this.isDirty = true;
  }

}

_registerDecorators(FileSecurity, {
  track: {
    fileTypes: 1,
    fileFields: 1
  }
})

export default _registerComponent(FileSecurity, {
  tmpl: _tmpl
});