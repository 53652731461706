import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./loginView.html";

/* eslint-disable @lwc/lwc/no-api-reassignments */
import zLightningElement from 'base/zLightningElement';
import { getLoggedInUser } from 'data/authService';

class LoginView extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.marquee = "";
    this.packageName = "";
    this.loggedUser = undefined;
    this.nextPage = "/";
    this.isSandbox = false;
  }

  get loginUrl() {
    return `/oauth/login?sfLoginType=${this.isSandbox ? 'test' : 'login'}&nextPage=${this.nextPage}`;
  }

  connectedCallback() {
    super.connectedCallback();
    console.debug('@@@@ Pulling logged-in user info @@@@');
    getLoggedInUser().then(userInfo => {
      if (userInfo.user_id === undefined) {
        console.debug("User is not logged in yet.");
        this.loggedUser = undefined;
        this.nextPage = window.location.pathname;
        return;
      }

      console.debug({
        userInfo
      });
      this.loggedUser = userInfo; // Now that we are logged in, show the accordion UI

      let event = new CustomEvent('show-yourself', {
        bubbles: true,
        cancelable: true
      }); // eslint-disable-next-line @lwc/lwc/no-document-query

      document.querySelector("#wizard").dispatchEvent(event);
    }).catch(errors => {
      console.warn(errors.statusText || "invalid session?");
    });
  }

  useSandbox(evt) {
    this.isSandbox = evt.target.value || false;
  }

}

_registerDecorators(LoginView, {
  publicProps: {
    marquee: {
      config: 0
    },
    packageName: {
      config: 0
    },
    loggedUser: {
      config: 0
    }
  },
  track: {
    nextPage: 1,
    isSandbox: 1
  }
})

export default _registerComponent(LoginView, {
  tmpl: _tmpl
});