import _implicitStylesheets from "./networkAccess.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {d: api_dynamic, t: api_text, h: api_element, k: api_key, i: api_iterator} = $api;
  return [api_element("label", {
    key: 0
  }, [api_dynamic($cmp.networkAccess.length), api_text(" Trusted IP Ranges:")]), api_element("table", {
    key: 1
  }, [api_element("thead", {
    key: 2
  }, [api_element("tr", {
    key: 3
  }, [api_element("th", {
    key: 4
  }, [api_text("Description")]), api_element("th", {
    key: 5
  }, [api_text("Start IP")]), api_element("th", {
    key: 6
  }, [api_text("End IP")])])]), api_element("tbody", {
    key: 7
  }, api_iterator($cmp.networkAccess, function (range) {
    return api_element("tr", {
      key: api_key(8, range.start)
    }, [api_element("td", {
      key: 9
    }, [api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "type": "checkbox",
        "disabled": ""
      },
      props: {
        "value": range.start,
        "checked": true
      },
      key: 10
    }, []), api_element("label", {
      key: 11
    }, [api_dynamic(range.description)])]), api_element("td", {
      key: 12
    }, [api_dynamic(range.start)]), api_element("td", {
      key: 13
    }, [api_dynamic(range.end)])]);
  }))])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_networkAccess-host",
  shadowAttribute: "my-modules-m2o_networkAccess"
};
