import _implicitStylesheets from "./verifyOrganization.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {k: api_key, d: api_dynamic, t: api_text, h: api_element, i: api_iterator, f: api_flatten, b: api_bind} = $api;
  const {_m0, _m1} = $ctx;
  return api_flatten([$cmp.deploymentFieldsLength ? api_flatten([api_iterator($cmp.deploymentFields, function (field) {
    return api_element("div", {
      classMap: {
        "form-group": true
      },
      key: api_key(0, field.key)
    }, [api_element("label", {
      key: 1
    }, [api_dynamic(field.label), api_text(":")]), api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "type": "text",
        "readonly": "readonly"
      },
      props: {
        "value": field.value
      },
      key: 2
    }, [])]);
  }), api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 3
  }, [api_text("If this information is not correct, please  "), api_element("a", {
    attrs: {
      "href": "/oauth/logout"
    },
    key: 4
  }, [api_text("logout")]), api_text(" of this app"), api_element("br", {
    key: 5
  }, []), api_text("and then authorize with the correct instance of Salesforce.")])]) : [], !$cmp.deploymentExists ? api_element("div", {
    classMap: {
      "warningMessage": true
    },
    key: 6
  }, [api_element("span", {
    classMap: {
      "warning": true
    },
    key: 7
  }, [api_text("WARNING: ")]), api_text("Before your organization can be configured, the zPaper Tech Support technicians must create a Deployment record in the zPaper database. Please contact zPaper Support: support@zpaper.com to request that the required Deployment record be created. Please also include your Salesforce organization Id in that request. You will not be able to continue configuration of the zPaper solution until that Deployment record is created. Please re-run this configuration wizard after you have received confirmation that your Deployment record has been created.")]) : null, !$cmp.matchedPackage ? api_element("div", {
    classMap: {
      "warningMessage": true
    },
    key: 8
  }, [api_element("span", {
    classMap: {
      "warning": true
    },
    key: 9
  }, [api_text("WARNING: ")]), api_text("This organization is registered for use with another zPaper Managed Package, which conflicts with the current Installation process. Please contact support@zpaper.com to request a review of your current Deployment record, and include your Salesforce organization Id. You will not be able to continue configuration of the zPaper solution until your Deployment record has been updated. Once you have received that confirmation, you will be able to return here and continue the installation.")]) : null, !$cmp.myDomainDeployed ? api_element("div", {
    classMap: {
      "warningMessage": true
    },
    key: 10
  }, [api_element("span", {
    classMap: {
      "warning": true
    },
    key: 11
  }, [api_text("WARNING: ")]), api_text("In order to configure the zPaper solution, your organization must be fully Lightning-enabled. This includes deploying 'My Domain' (creation of your custom Salesforce domain). Click the 'Deploy My Domain' button to view the Salesforce page that will guide you through the process (takes about 30 minutes). When that process is complete, come back here and either reload the page or click the 'Reload' button. You cannot continue with this zPaper configuration until you have deployed your custom domain.")]) : null, !$cmp.myDomainDeployed ? api_element("div", {
    classMap: {
      "btnContainer": true
    },
    key: 12
  }, [api_element("button", {
    key: 13,
    on: {
      "click": _m0 || ($ctx._m0 = api_bind($cmp.handleMyDomainClick))
    }
  }, [api_text(" Deploy My Domain ")]), api_element("button", {
    classMap: {
      "right": true
    },
    key: 14,
    on: {
      "click": _m1 || ($ctx._m1 = api_bind($cmp.handleReloadClick))
    }
  }, [api_text(" Reload ")])]) : null]);
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_verifyOrganization-host",
  shadowAttribute: "my-modules-m2o_verifyOrganization"
};
