import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./consentAgreement.html";

/* eslint-disable dot-notation */
import zLightningElement from 'base/zLightningElement';
import { updateDeployment } from 'data/zpaperConfigService';

class ConsentAgreement extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.documentUrl = null;
    this.deploymentRecord = {};
    this.isRegistered = false;
    this.agreedTimestamp = null;
  }

  get signedAgreement() {
    return this.agreedTimestamp != null;
  }

  connectedCallback() {
    super.connectedCallback(); // eslint-disable-next-line @lwc/lwc/no-document-query

    let loginView = document.querySelector('login-view');
    let loggedUser = loginView && loginView.loggedUser;

    if (!loggedUser) {
      console.error('User is not logged in!');
      return;
    } // eslint-disable-next-line @lwc/lwc/no-document-query


    let verifyOrg = document.querySelector("verify-organization");
    let deployment = verifyOrg && verifyOrg.deploymentRecord; // this.packageList = verifyOrg && verifyOrg.packageList;

    this.isRegistered = !!deployment;
    this.agreedTimestamp = deployment && deployment.User_Provisioning__c; // check the consent agreement timestamp

    this.isDirty = !this.isRegistered; // no changes needed for existing deployments

    this.deploymentRecord = deployment;
  }

  async saveYourself() {
    this.completed = !!this.agreedTimestamp; //SHR211021 make sure it is signed before continuing

    if (!this.isDirty || !this.agreedTimestamp) {
      //SHR211012 we are done if we made no changes or didn't check the box
      if (!this.agreedTimestamp) {
        alert('Please read and sign the Consent Agreement before proceeding...');
      }

      return;
    } // Update the deployment record with the timestamp and discovered package list (if Notes are empty)


    this.deploymentRecord.User_Provisioning__c = this.agreedTimestamp; // if (!this.deploymentRecord.Notes__c && !!this.packageList && this.packageList.length) {
    //     //TODO: update existing Notes__c without over-writing other info, or endlessly appending this list
    //     let notes = {
    //         "InstalledPackages": this.packageList
    //     }
    //     this.deploymentRecord.Notes__c = JSON.stringify(notes, null, 2);
    // }

    await updateDeployment(this.deploymentRecord).then(result => {
      // console.debug(`Result status: ${result.status}`);
      console.debug('Result:', result); // if (result && result.status == "success") {
      //     this.isDirty = false; // Don't update this same data again, unless a field is changed
      // }
      // this.completed = !this.isDirty;

      this.completed = true; //SHR211011 TODO: figure out why the result has no status?!?

      return result;
    }).catch(errors => {
      // eslint-disable-next-line no-alert, no-restricted-globals
      this.completed = !this.deploymentRecord.Primary_Login_Password__c || confirm(errors);
      return errors;
    });
  }

  handleCheckbox(evt) {
    let agreedTo = evt.target && evt.target.checked;
    this.isDirty = true; // something changed, mark it as dirty

    this.agreedTimestamp = agreedTo ? new Date().toISOString().split('T')[0] : null;
  }

}

_registerDecorators(ConsentAgreement, {
  publicProps: {
    documentUrl: {
      config: 0
    }
  },
  track: {
    deploymentRecord: 1,
    isRegistered: 1,
    agreedTimestamp: 1
  }
})

export default _registerComponent(ConsentAgreement, {
  tmpl: _tmpl
});