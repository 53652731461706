import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./verifyOrganization.html";

/* eslint-disable dot-notation */
import zLightningElement from 'base/zLightningElement';
//I'm trying to learn how to use this in LSC.OSS. For now just use alerts
// import {ShowToastEvent} from 'lightning/platformShowToastEvent';
import { getDeploymentRecord } from 'data/zpaperConfigService'; // import { getInstalledPackages } from 'data/salesforceConfigService';

class VerifyOrganization extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.deploymentRecord = {};
    this.deploymentFields = [];
    this.deploymentFieldsLength = 0;
    this.deploymentExists = true;
    this.myDomainDeployed = true;
    this.matchedPackage = true;
    this.zPackage = "";
    this.sfServer = null;
    this.isInitialized = false;
  }

  connectedCallback() {
    super.connectedCallback(); // eslint-disable-next-line @lwc/lwc/no-document-query

    let loginView = document.querySelector('login-view');
    let loggedUser = loginView && loginView.loggedUser;

    if (!loggedUser) {
      console.error('User is not logged in!');
      return;
    } // eslint-disable-next-line @lwc/lwc/no-api-reassignments


    this.sfServer = loggedUser.urls.custom_domain || loggedUser.urls.rest; // eslint-disable-next-line @lwc/lwc/no-api-reassignments

    this.sfServer = this.sfServer.split(/[/]+/)[1];
    console.debug("this.sfServer =>", this.sfServer);

    if (!this.sfServer.split(".").includes("my")) {
      // Does the url contain .my. ?
      // Setting this to false will prevent the user from going further with the wizard until the window is reloaded.
      this.completed = false;
      this.myDomainDeployed = false;
    } // const evt = new ShowToastEvent({
    //     title: "This is the Title",
    //     message: "Hello World",
    //     variant: "error"
    // });
    // this.dispatchEvent(evt);


    getDeploymentRecord(loggedUser.organization_id).then(deployment => {
      this.isInitialized = true;

      if (deployment.message || deployment.errorMessage) {
        this.completed = false;
        this.deploymentExists = false;
        return;
      }

      let sfOrgId = deployment.Salesforce_Org_18_char__c || loggedUser.organization_id;
      let sfUsername = deployment.Primary_User_Login__c || loggedUser.username;
      let clientEmail = deployment.Client_Email__c || loggedUser.email;
      let verifyFields = [{
        key: "Name",
        label: "Deployment Name",
        value: deployment.Name
      }, {
        key: "Salesforce_Org_18_char__c",
        label: "Organization ID",
        value: sfOrgId
      }, {
        key: "SF_Server__c",
        label: "Salesforce Server",
        value: this.sfServer
      }, {
        key: "Primary_User_Login__c",
        label: "Salesforce Username",
        value: sfUsername
      }, {
        key: "Client_Email__c",
        label: "Email Address",
        value: clientEmail
      }];
      console.debug({
        verifyFields
      }); // eslint-disable-next-line @lwc/lwc/no-api-reassignments

      this.zPackage = deployment.Package__c; //SHR211019 check the package prefix of the deployment record is correct...

      switch (this.zPackage) {
        case "ZPAPER__":
          this.matchedPackage = true; //SHR211019 no restrictions on installing along side the base packages

          break;

        case "ZPAPER6__":
          this.matchedPackage = loginView.packageName === "zPaper:Transport"; //SHR211019 package prefix must match this installer

          break;

        case "__ZMERGE":
          this.matchedPackage = loginView.packageName === "zPaper:Merge"; //SHR211019 package prefix must match this installer

          break;

        default:
          this.matchedPackage = true; //SHR211019 package prefix not set, so allow installs, I suppose?

          break;
      } // eslint-disable-next-line @lwc/lwc/no-api-reassignments


      this.deploymentExists = true;
      this.deploymentRecord = deployment;
      this.deploymentFieldsLength = verifyFields.length;
      this.deploymentFields = verifyFields;
    }).catch(error => {
      console.error("Error received when retrieving Deployment record.");
      console.error(error);
      this.deploymentExists = false;
    });
  }

  async saveYourself() {
    if (!this.deploymentExists) {
      // eslint-disable-next-line no-alert
      alert("You must follow the given instructions to get your Deployment record created before you can continue.");
      return;
    }

    if (!this.matchedPackage) {
      // eslint-disable-next-line no-alert
      alert("You must follow the given instructions to get your Deployment record updated before you can continue.");
      return;
    }

    if (!this.myDomainDeployed) {
      // eslint-disable-next-line no-alert
      alert("You must follow the given instructions to deploy your custom Salesforce domain before you can continue. " + "If you have successfully deployed your custom domain, please click the 'Reload' button to reload this " + "wizard and continue with configuration of the zPaper application.");
      return;
    }

    this.completed = true; // move along to next tab
  } // eslint-disable-next-line no-unused-vars


  handleMyDomainClick(evt) {
    let w = window.open("https://" + this.sfServer + "/lightning/setup/OrgDomain/home", 'setMyDomain', 'resizable=1');
    w.focus();
  } // eslint-disable-next-line no-unused-vars


  handleReloadClick(evt) {
    window.location.reload();
  }

}

_registerDecorators(VerifyOrganization, {
  publicProps: {
    deploymentRecord: {
      config: 0
    },
    zPackage: {
      config: 0
    },
    sfServer: {
      config: 0
    }
  },
  track: {
    deploymentFields: 1,
    deploymentFieldsLength: 1,
    deploymentExists: 1,
    myDomainDeployed: 1,
    matchedPackage: 1,
    isInitialized: 1
  }
})

export default _registerComponent(VerifyOrganization, {
  tmpl: _tmpl
});