import _implicitStylesheets from "./customSettings.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {h: api_element, t: api_text, b: api_bind, k: api_key, i: api_iterator} = $api;
  const {_m0, _m1, _m2, _m3, _m4} = $ctx;
  return [api_element("div", {
    classMap: {
      "container": true
    },
    key: 0
  }, [api_element("div", {
    classMap: {
      "top-right": true
    },
    key: 1
  }, []), api_element("label", {
    key: 2
  }, [api_text("Choose your cloud:")]), api_element("table", {
    key: 3
  }, [api_element("tbody", {
    key: 4
  }, [api_element("tr", {
    key: 5
  }, [api_element("td", {
    key: 6
  }, [api_element("input", {
    attrs: {
      "type": "radio",
      "name": "cloud"
    },
    props: {
      "value": "HC"
    },
    key: 7,
    on: {
      "change": _m0 || ($ctx._m0 = api_bind($cmp.handleChange))
    }
  }, []), api_text("Health Cloud")]), api_element("td", {
    key: 8
  }, [api_element("input", {
    attrs: {
      "type": "radio",
      "name": "cloud"
    },
    props: {
      "value": "SC"
    },
    key: 9,
    on: {
      "change": _m1 || ($ctx._m1 = api_bind($cmp.handleChange))
    }
  }, []), api_text("Service Cloud")]), api_element("td", {
    key: 10
  }, [api_element("input", {
    attrs: {
      "type": "radio",
      "name": "cloud"
    },
    props: {
      "value": "SF"
    },
    key: 11,
    on: {
      "change": _m2 || ($ctx._m2 = api_bind($cmp.handleChange))
    }
  }, []), api_text("Sales Cloud")]), api_element("td", {
    key: 12
  }, [api_element("input", {
    attrs: {
      "type": "radio",
      "name": "cloud"
    },
    props: {
      "value": "PC"
    },
    key: 13,
    on: {
      "change": _m3 || ($ctx._m3 = api_bind($cmp.handleChange))
    }
  }, []), api_text("Platform Cloud")])])])]), api_element("dl", {
    key: 14
  }, api_iterator($cmp.customSettings, function (setting) {
    return api_element("dd", {
      key: api_key(15, setting.name)
    }, [api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "data-fld-name": setting.name,
        "type": "hidden",
        "type0": "text"
      },
      props: {
        "value": setting.value
      },
      key: 16,
      on: {
        "change": _m4 || ($ctx._m4 = api_bind($cmp.handleChange))
      }
    }, [])]);
  }))])];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_customSettings-host",
  shadowAttribute: "my-modules-m2o_customSettings"
};
