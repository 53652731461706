import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./channelAssignments.html";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable dot-notation */
import zLightningElement from 'base/zLightningElement';
import { clearRedisCache } from 'data/zpaperConfigService';
import { getCustomSettings, saveCustomSettings, getGroupQueues } from 'data/salesforceConfigService';

class ChannelAssignments extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.loggedUser = null;
    this.deploymentRecord = {};
    this.channelList = [];
    this.faxNumbers = [];
    this.sfQueues = [];
    this.settingsMap = {};
  }

  // channelOwner(elem) {
  //     let chan = this.channelList[elem.data.channelId] || {};
  //     return chan && chan.settings && chan.settings.owner || this.loggedUser.user_id;
  // }
  get channelSettingExists() {
    console.info(this);
    return true; //this.name && this.name.startsWith('ZPAPER6__');
  }

  connectedCallback() {
    super.connectedCallback(); // eslint-disable-next-line @lwc/lwc/no-document-query

    let loginView = document.querySelector('login-view');
    this.loggedUser = loginView && loginView.loggedUser;

    if (!this.loggedUser) {
      console.error('User is not logged in!');
      return;
    } // eslint-disable-next-line @lwc/lwc/no-document-query


    let verifyOrg = document.querySelector('verify-organization');
    this.deploymentRecord = verifyOrg && verifyOrg.deploymentRecord;

    if (!this.deploymentRecord) {
      console.error('Deployment Record is missing for this Org!');
      return;
    }

    getCustomSettings().then(settings => {
      // preserve the Custom Settings object as a flat object that contains name/value pairs
      Object.keys(settings).forEach(key => {
        this.settingsMap[key] = settings[key].value;
      }); // Flatten/Filter the map into an array that can be rendered in the template

      let prefixList = ['']; //SHR211026 keep track of custom setting field names, as some have a MP prefix

      const prefix = this.deploymentRecord.Package__c || '';
      const regex = new RegExp(`^(?<pfx>${prefix})?zChannel(?<num>\\d+)__c`);

      for (let key in settings) {
        if (!Object.prototype.hasOwnProperty.call(settings, key)) {
          continue;
        }

        let grp = regex.exec(key);

        if (!grp) {
          continue;
        }

        prefixList[+grp.groups.num] = grp.groups.pfx || ''; //SHR211026 save custom setting prefix

        let val = this.settingsMap[key];

        let def = _objectSpread({}, settings[key]); // clone the original settings obj


        try {
          def.settings = val ? JSON.parse(val) : {};
        } catch (err) {
          console.warn(`Expression (type: ${typeof val}, length = ${val.length}) is not valid JSON!`, val);

          try {
            if (val.includes("'") && !val.includes('"')) {
              console.info('Convert to double quotes...');
              val = val.replace(/'/g, '"');
              def.settings = JSON.parse(val);
            }
          } catch (err2) {
            if (val.trim().startsWith('{') && val.trim().endsWith('}')) {
              console.info('Last resort: trying eval...');

              try {
                // eslint-disable-next-line no-eval
                def.settings = eval("() => {return " + val + ";}")();
              } catch (err3) {
                console.warn(key, 'is not readable! skipping it...');
                def.settings = {};
              }
            }
          }
        } // If we have a valid channel def, add it to the map


        let valid = val && Object.keys(def.settings).length > 0;

        if (valid) {
          this.settingsMap[key] = JSON.stringify(def.settings);
        }
      } // Build the list of channels from the Fax number list, not the settings


      let channels = [];
      this.faxNumbers = this.deploymentRecord.zPaper_Fax__c.split(',').filter(num => num); // remove any blank entries

      if (this.faxNumbers.length === 0 || this.faxNumbers[0] !== '') {
        this.faxNumbers.unshift(''); // ensure there is an empty slot at the start of the list
      }

      this.faxNumbers.forEach((num, idx) => {
        console.info(`${idx}: Creating channel def for fax #${num}`);
        let grpId = this.deploymentRecord.KBin_Master_ID__c || '00000';
        let pfx = prefixList[idx] || ''; //SHR211026 lookup custom setting prefix

        let chan = {
          id: idx,
          json: '',
          // the original json string from custom settings
          name: `${pfx}zChannel${idx}__c`,
          label: `zChannel${idx}`,
          value: '',
          // the current json string of the channel object
          valid: false,
          // flag for styling when errors are found
          settings: {
            path: '',
            action: '',
            fax: num,
            email: `${num}@mfb.zpaper.com`,
            owner: ``,
            'db-users': `:${grpId}:`,
            'db-readers': `:${grpId}:`
          }
        }; // locate any existing channel for this fax number

        for (let cno = 1; cno < this.faxNumbers.length; cno++) {
          let json = this.settingsMap[`${pfx}zChannel${cno}__c`] // check package managed field first
          || this.settingsMap[`zChannel${cno}__c`] // fallback to unmanaged field, if not found
          || '{}';
          let def = JSON.parse(json);

          if (def && def.fax === num) {
            console.info(`${idx}: Re-using channel def from setting #${cno}`);
            chan.json = json;
            chan.settings = def;
            break;
          }
        } // check for errors in data or missing channel settings


        if (chan.idx && this.settingsMap[chan.name]) {
          chan.valid = true;
        } // append the found (or default) channel def to the list


        channels.push(chan);
        console.log({
          chan
        });
      }); // Update the form fields from the channel list settings...

      this.channelList = channels; // Update the selected options to match the settings...

      this.isDirty = false;
      this.completed = true; // eslint-disable-next-line @lwc/lwc/no-async-operation

      setTimeout(() => {
        // Set the form values from the channel list settings
        let $form = $(this.template.querySelector('form'));

        for (let idx = 1; idx < this.channelList.length; idx++) {
          let chan = JSON.parse(this.channelList[idx].json || '{}');
          let $sel = $form.find(`select[data-channel-id='${idx}']`); // do not require updating the custom settings, unless changes are found

          this.isDirty = this.isDirty || $sel.attr('value') !== chan.owner;

          if (chan.owner) {
            $sel.val(chan.owner);
          }
        }
      }, 500); // give the DOM a bit of time to update
    }).catch(error => {
      console.error(error);
    });
    getGroupQueues().then(queues => {
      this.sfQueues = queues || [];
    }).catch(error => {
      console.error(error);
    });
  }

  readAssignment(idx) {
    // Retrieve the values from the checkboxes and build a channel object
    let form = this.template.querySelector('form'); // eslint-disable-next-line no-undef

    let $form = $(form);
    let grpId = this.deploymentRecord.KBin_Master_ID__c; // let cPath = $form.find('input[name="zChannelPath"]').val() || '';

    let cPath = $form.find(`select[data-channel-id=${idx}] :selected`).prop("label"); // let faxNo = $form.find('div.edit-box select[name="faxNo"]').val();

    let faxNo = $form.find(`input[data-channel-id=${idx}]`).val(); // let fldId = $form.find('div.edit-box input[name="scanDir"]').val();
    // let bktId = $form.find('div.edit-box input[name="s3Bucket"]').val();
    // let dbIds = $form.find('div.edit-box select[name="sharedGroups"]').val() || [];

    let dbIds = [grpId]; // let oType = $form.find('div.edit-box select[name="objectType"]').val() || '';
    // let rType = $form.find('div.edit-box select[name="recordType"]').val() || '';

    let oType = '';
    let rType = ''; // eslint-disable-next-line no-undef-init

    let creds = undefined; // TODO: gather this info and save it
    // let $outs = $form.find('div.edit-box :checkbox[name$="Out"]:checked');
    // eslint-disable-next-line no-unused-vars
    // let [client, program, drug] = cPath.split('/');
    // let prefix = !drug || faxNo.includes(drug) ? faxNo : `${faxNo}.${drug}`;
    // let defAct = $form.find('div.edit-box :checkbox[name="createType"]').prop('checked') && oType ? `client${oType}${rType}()` : '';

    let channel = {
      'path': cPath,
      'fax': faxNo,
      'email': faxNo ? `${faxNo}@mfb.zpaper.com` : undefined,
      // 'secure': $form.find('div.edit-box :checkbox[name="secureIn"]').prop('checked') && faxNo ? `${prefix}@zpaper.direct.kno2fy.com` : undefined,
      // 'scan': $form.find('div.edit-box :checkbox[name="scanIn"]').prop('checked') ? fldId : undefined,
      // 's3': $form.find('div.edit-box :checkbox[name="s3In"]').prop('checked') ? bktId : undefined,
      // 'sftp': $form.find('div.edit-box :checkbox[name="sftpIn"]').prop('checked') ? creds : undefined,
      // 'upload': $form.find('div.edit-box :checkbox[name="uploadIn"]').prop('checked') ? grpId : undefined,
      // 'action': $form.find('div.edit-box input[name="clientAction"]').val() || defAct,
      'action': '',
      //SHR211014 action rules fail without this?!?
      // 'create': $form.find('div.edit-box :checkbox[name="createType"]').prop('checked') && oType ? (rType ? `${oType}:${rType}` : oType) : undefined,
      'owner': $form.find(`select[data-channel-id=${idx}]`).val() || this.loggedUser.user_id,
      // 'deliver': $.map($outs, out => out.name.replace(/Out$/, '')).join(','),
      'db-users': ':' + dbIds.join(':') + ':',
      'db-readers': ':' + dbIds.join(':') + ':'
    };
    return channel;
  }

  handleSelect(evt) {
    let sel = evt && evt.target;
    let data = $(sel).data();

    if (data && data.channelId) {
      this.updateChannel(data.channelId);
      this.isDirty = true;
      this.completed = false;
    }
  }

  updateChannel(chanNo) {
    let idx = chanNo || this.data.channelId;
    console.log('Update channel #', idx);
    let channelDef = this.readAssignment(idx);
    this.channelList[idx].settings = channelDef;
    let jsonDef = JSON.stringify(channelDef);

    if (jsonDef.length > 255) {
      console.warn('Channel Definition is TOO LONG to be saved in Salesforce!');
      console.info({
        channelDef
      });
    }

    this.channelList[idx].value = jsonDef;
  }

  async saveYourself() {
    if (!this.isDirty) {
      this.completed = true;
      return;
    }

    this.handleSave();
  }

  handleSave(evt) {
    let form = evt && evt.target && evt.target.form || this.template.form;

    if (form) {
      this.updatedSettings = {
        Id: this.settingsMap.Id
      };

      for (let cno = 1; cno < this.channelList.length; cno++) {
        this.updateChannel(cno);
        let chan = this.channelList[cno];
        this.updatedSettings[chan.name] = JSON.stringify(chan.settings);
      }

      this.isDirty = true;
      saveCustomSettings(this.updatedSettings).then(response => {
        if (response && response.errorCode) {
          throw new Error(response.errorCode);
        }

        if (response && response.errors && response.errors.length) {
          throw new Error(response.errors.join('\n'));
        }

        console.info({
          customSettings: response
        });
        this.updatedSettings = {};
        this.isDirty = false;
        this.completed = true;
        clearRedisCache().then(reset => {
          if (reset.status !== 'success') {
            throw new Error(JSON.stringify(reset));
          }
        }).catch(error => {
          console.warn("Custom Settings saved, but the Redis cache was not reset!");
          console.error(error);
        }); //SHR211014 need to update the master group folder path -- but there is no endpoint for that yet?
        // updateGroupPath()
        //     .then(result => {
        //         if (result.status !== 'success') {
        //             console.warn(result);
        //         }
        //     })
        //     .catch(error => {
        //         console.warn("Update to the Group Actions path was not successful!");
        //         console.error(error);
        //     });
      }).catch(errors => {
        console.error(errors); // eslint-disable-next-line no-alert

        alert(`Error(s) in saving Channel Settings:\n ${errors}`);
      }); // let $btn = $(form).find('input[type=button]');
      // $btn.prop('disabled', !this.isDirty);
    }
  }

}

_registerDecorators(ChannelAssignments, {
  track: {
    loggedUser: 1,
    deploymentRecord: 1,
    channelList: 1,
    faxNumbers: 1,
    sfQueues: 1
  },
  fields: ["settingsMap"]
})

export default _registerComponent(ChannelAssignments, {
  tmpl: _tmpl
});