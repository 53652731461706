import { registerDecorators as _registerDecorators, registerComponent as _registerComponent } from "lwc";
import _tmpl from "./connectedApp.html";
import zLightningElement from 'base/zLightningElement';
import { getConnectedAppSettings, addProfilesToConnectedApp } from 'data/salesforceConfigService';

class ConnectedApp extends zLightningElement {
  constructor(...args) {
    super(...args);
    this.connectedAppName = '';
    this.connectedApp = {};
    this.fromProfiles = [];
    this.toProfiles = [];
    this.OptionsAllowAdminApprovedUsersOnly = false;
    this.verifyConnectedAppClicked = false;
    this.tabInitialized = false;
  }

  // get connectedAppName() {
  //     return this.connectedApp && this.connectedApp.name || 'zPaper Enabler';
  // }
  connectedCallback() {
    super.connectedCallback();
    getConnectedAppSettings(this.connectedAppName).then(result => {
      this.tabInitialized = true;
      console.debug({
        result
      });

      if ("success" === result.status) {
        this.connectedApp = result.connectedApp; // Start with the Administrator and Standard User Profiles in the right-hand side list.

        this.fromProfiles = result.profiles.filter(profile => {
          return profile.Name !== 'System Administrator' && profile.Name !== 'Standard User';
        });
        this.toProfiles = result.profiles.filter(profile => {
          return profile.Name === 'System Administrator' || profile.Name === 'Standard User';
        });
        this.OptionsAllowAdminApprovedUsersOnly = this.connectedApp && this.connectedApp.OptionsAllowAdminApprovedUsersOnly;

        if (this.OptionsAllowAdminApprovedUsersOnly) {
          // If the Connected App is already setup correctly, there is no need to click the 'Verify' button.
          this.verifyConnectedAppClicked = true;
        } // Always add the Administrator and Standard Users


        let data = {
          connectedAppId: result.connectedApp.Id,
          profiles: this.toProfiles
        };
        addProfilesToConnectedApp(data).then(output => {
          console.log({
            result: output
          }); // The result looks like this:
          // { status: "success" | "failed", message: "something", errors: [array of error entries from all requests],
          //   result: [
          //             { success: true | false,
          //               errors: [{ statusCode: DUPLICATE_VALUE, message: "something", fields: []}, ...] } ] }
          // if ("success" === output.status) {
          // }
        });
      }
    }).catch(error => {
      this.tabInitialized = true;
      console.error(error);
    });
  }

  async saveYourself() {
    if (!this.verifyConnectedAppClicked || !this.OptionsAllowAdminApprovedUsersOnly) {
      // eslint-disable-next-line no-alert
      alert("The zPaper solution will not perform correctly if the '" + this.connectedAppName + "' Connected App " + "hasn't been configured as 'Admin approved users are pre-authorized'. You must configure " + "the Connected App by following the instructions given in this section before you can continue. You must " + "then click 'Verify Connected App and Continue' to check your changes.");
      return;
    }

    let data = {
      connectedAppId: this.connectedApp.Id,
      profiles: this.toProfiles
    };
    await addProfilesToConnectedApp(data).then(result => {
      console.log({
        result
      }); // The result looks like this:
      // { status: "success" | "failed", message: "something", errors: [array of error entries from all requests],
      //   result: [
      //             { success: true | false,
      //               errors: [{ statusCode: DUPLICATE_VALUE, message: "something", fields: []}, ...] } ] }

      if ("success" === result.status) {
        this.completed = true;
      } else {
        // eslint-disable-next-line no-alert
        alert(result.message);
      }

      return result;
    });
  } // eslint-disable-next-line no-unused-vars


  connectedAppClick(evt) {
    // eslint-disable-next-line @lwc/lwc/no-document-query
    let verifyOrg = document.querySelector("verify-organization");
    let sfServer = verifyOrg.sfServer || "unknown"; // Get the sfServer from the log-in information

    if (!sfServer.startsWith("https")) {
      sfServer = "https://" + sfServer;
    }

    console.log({
      sfServer
    });
    let aURL = sfServer + "/app/mgmt/forceconnectedapps/forceInstalledConnectedAppList.apexp";
    let w = window.open(aURL, 'zConnectApp', 'resizable=1');
    w.focus();
  } // eslint-disable-next-line no-unused-vars


  verifyConnectedAppClick(evt) {
    // Check the Connected App again to be sure that the "Admin approved users are pre-authorized" setting is set.
    getConnectedAppSettings(this.connectedAppName).then(result => {
      console.debug({
        result
      });

      if ("success" === result.status) {
        if (!result.connectedApp || !result.connectedApp.OptionsAllowAdminApprovedUsersOnly) {
          // eslint-disable-next-line no-alert
          alert("The 'zPaper Enabler' Connected App is still not configured correctly. Please follow the " + "instructions given in the Configuration Wizard.");
          return;
        }

        this.verifyConnectedAppClicked = true;
        this.connectedApp = result.connectedApp;
        this.OptionsAllowAdminApprovedUsersOnly = result.connectedApp.OptionsAllowAdminApprovedUsersOnly;
      } else {
        // If we couldn't pull information for the Connected App, just reload the application.
        window.location.reload(); // eslint-disable-next-line no-alert
        // alert("Error verifying the 'zPaper Enabler' Connected App. Please try clicking the 'Verify Connected " +
        //     "App and Continue' button again.");
      }
    });
  } // eslint-disable-next-line no-unused-vars


  moveProfilesRight(evt) {
    let optsToMove = this.getSelectedOptions(".from-select");
    let newLists = this.robPeterToPayPaul(this.fromProfiles, this.toProfiles, optsToMove);
    this.fromProfiles = newLists.fromList;
    this.toProfiles = newLists.toList;
  } // eslint-disable-next-line no-unused-vars


  moveProfilesLeft(evt) {
    let optsToMove = this.getSelectedOptions(".to-select");
    let newLists = this.robPeterToPayPaul(this.toProfiles, this.fromProfiles, optsToMove);
    this.toProfiles = newLists.fromList;
    this.fromProfiles = newLists.toList;
  }

  getSelectedOptions(selector) {
    let fromSelect = this.template.querySelector(selector);
    let selectedOptions = [];
    let options = fromSelect && fromSelect.options;

    for (let idx = 0, iLen = options.length; idx < iLen; idx++) {
      let opt = options[idx];

      if (opt.selected) {
        selectedOptions.push(opt.value);
      }
    }

    return selectedOptions;
  }

  robPeterToPayPaul(fromList, toList, optsToMove) {
    let profilesToMove = [];

    if (optsToMove.length > 0) {
      fromList = fromList.filter(profile => {
        if (optsToMove.includes(profile.Id)) {
          profilesToMove.push(profile);
          return false;
        }

        return true;
      });
    }

    if (profilesToMove.length > 0) {
      toList = toList.concat(profilesToMove);
    }

    return {
      fromList: fromList,
      toList: toList
    };
  }

}

_registerDecorators(ConnectedApp, {
  publicProps: {
    connectedAppName: {
      config: 0
    }
  },
  track: {
    connectedApp: 1,
    fromProfiles: 1,
    toProfiles: 1,
    OptionsAllowAdminApprovedUsersOnly: 1,
    verifyConnectedAppClicked: 1,
    tabInitialized: 1
  }
})

export default _registerComponent(ConnectedApp, {
  tmpl: _tmpl
});