import _implicitStylesheets from "./connectedApp.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {t: api_text, h: api_element, d: api_dynamic, b: api_bind, k: api_key, i: api_iterator} = $api;
  const {_m0, _m1, _m2, _m3} = $ctx;
  return [$cmp.tabInitialized ? !$cmp.OptionsAllowAdminApprovedUsersOnly ? api_element("div", {
    classMap: {
      "warningMessage": true
    },
    key: 0
  }, [api_element("span", {
    classMap: {
      "warning": true
    },
    key: 1
  }, [api_text("WARNING:")]), api_text(" In order for the zPaper solution to function, the '"), api_dynamic($cmp.connectedAppName), api_text("' Connected App must be configured directly in Salesforce. Please follow the steps below."), api_element("ol", {
    classMap: {
      "warning-list": true
    },
    key: 2
  }, [api_element("li", {
    key: 3
  }, [api_text("Click the 'Configure Connected App' button below. This will open a Salesforce setup screen in a new window.")]), api_element("li", {
    key: 4
  }, [api_text("Click the 'Edit' link next to '"), api_dynamic($cmp.connectedAppName), api_text("' (Note: please ignore other options with the words zPaper)")]), api_element("li", {
    key: 5
  }, [api_text("Navigate to the field entitled, 'Permitted Users'.  It is a picklist.  Choose the value: 'Admin approved users are pre-authorized'.  Upon doing this you will receive a pop-up window with a message warning you that taking this action will result in users currently in the App being denied access.  Click 'OK'.")]), api_element("li", {
    key: 6
  }, [api_text("Navigate to the bottom of the detail section and click the 'Save' button. This will result in returning you to the screen entitled 'Connected Apps'.")]), api_element("li", {
    key: 7
  }, [api_text("Close the window and return to this zPaper Configuration wizard.")]), api_element("li", {
    key: 8
  }, [api_text("Click the 'Verify Connected App and Continue' button below.  Note that doing so will require you to login into the Configuration wizard again and to proceed through Step #1 again.  However, after clicking the 'Next' button in Step #1, you will see a different Step #2 page as you have successfully enabled the Connected App.")])])]) : null : null, $cmp.tabInitialized ? !$cmp.OptionsAllowAdminApprovedUsersOnly ? api_element("div", {
    classMap: {
      "btnContainer": true
    },
    key: 9
  }, [api_element("button", {
    key: 10,
    on: {
      "click": _m0 || ($ctx._m0 = api_bind($cmp.connectedAppClick))
    }
  }, [api_text(" Configure Connected App ")]), api_element("button", {
    classMap: {
      "right": true
    },
    key: 11,
    on: {
      "click": _m1 || ($ctx._m1 = api_bind($cmp.verifyConnectedAppClick))
    }
  }, [api_text(" Verify Connected App and Continue ")])]) : null : null, $cmp.tabInitialized ? $cmp.OptionsAllowAdminApprovedUsersOnly ? api_element("div", {
    classMap: {
      "container": true
    },
    key: 12
  }, [api_text("Select the Profiles of the Users that will be interacting with the zPaper solution. The selected Profiles will be enabled for the '"), api_dynamic($cmp.connectedAppName), api_text("' Connected App. The System Administrator and Standard User Profiles have already been added to the list. If you do not know all of the profiles that will need to use zPaper, you can add them later."), api_element("div", {
    classMap: {
      "selectHolder": true
    },
    key: 13
  }, [api_element("table", {
    key: 14
  }, [api_element("tbody", {
    key: 15
  }, [api_element("tr", {
    key: 16
  }, [api_element("td", {
    key: 17
  }, [api_element("div", {
    classMap: {
      "listHeader": true
    },
    key: 18
  }, [api_text("All Profiles")]), api_element("select", {
    classMap: {
      "from-select": true
    },
    attrs: {
      "multiple": "multiple"
    },
    key: 19
  }, api_iterator($cmp.fromProfiles, function (profile) {
    return api_element("option", {
      attrs: {
        "value": profile.Id
      },
      key: api_key(20, profile.Id)
    }, [api_dynamic(profile.Name)]);
  }))]), api_element("td", {
    key: 21
  }, [api_element("input", {
    classMap: {
      "btn-move": true
    },
    attrs: {
      "type": "button"
    },
    props: {
      "value": "  >>>  "
    },
    key: 22,
    on: {
      "click": _m2 || ($ctx._m2 = api_bind($cmp.moveProfilesRight))
    }
  }, []), api_element("br", {
    key: 23
  }, []), api_element("input", {
    classMap: {
      "btn-move": true,
      "btn-left": true
    },
    attrs: {
      "type": "button"
    },
    props: {
      "value": "  <<<  "
    },
    key: 24,
    on: {
      "click": _m3 || ($ctx._m3 = api_bind($cmp.moveProfilesLeft))
    }
  }, [])]), api_element("td", {
    key: 25
  }, [api_element("div", {
    classMap: {
      "listHeader": true
    },
    key: 26
  }, [api_text("Profiles Enabled to Use zPaper")]), api_element("select", {
    classMap: {
      "to-select": true
    },
    attrs: {
      "multiple": "multiple"
    },
    key: 27
  }, api_iterator($cmp.toProfiles, function (profile) {
    return api_element("option", {
      attrs: {
        "value": profile.Id
      },
      key: api_key(28, profile.Id)
    }, [api_dynamic(profile.Name)]);
  }))])])])])])]) : null : null];
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_connectedApp-host",
  shadowAttribute: "my-modules-m2o_connectedApp"
};
