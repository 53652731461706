export async function fetchApi(apiUrl, apiKey) {
  const options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  };

  if (apiKey) {
    options.headers['X-API-Key'] = apiKey;
  }

  console.info('GET', apiUrl);
  let mark = Date.now();
  let result = await fetch(apiUrl, options).then(res => {
    if (res.ok) {
      return res.text();
    }

    throw new Error(`${res.status} ${res.statusText}`);
  }).then(out => {
    let obj = null;

    try {
      obj = JSON.parse(out);
      console.info('OBJ', apiUrl, '=>', elapsedTime(mark));
      console.debug(obj);
    } catch (e) {
      // throw new Error(out);
      // Not every rest api returns valid JSON -- so just return the text?
      console.info('OUT', apiUrl, '=>', elapsedTime(mark));
      console.debug(out);
    }

    return obj || out;
  }).catch(err => {
    console.warn('ERR', apiUrl, '=>', elapsedTime(mark));
    console.error(err);
    return err;
  });
  return result;
}
export async function postApi(apiUrl, jsonData, apiKey) {
  let body = jsonData ? JSON.stringify(jsonData) : ""; // request options

  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: body
  };

  if (apiKey) {
    options.headers['X-API-Key'] = apiKey;
  } // send POST request


  console.info('POST', apiUrl, ' =>', body ? body.length + ' bytes' : body);
  console.debug(jsonData);
  let mark = Date.now();
  let result = await fetch(apiUrl, options).then(res => {
    if (res.ok) {
      return res.text();
    }

    throw new Error(`${res.status} ${res.statusText}`);
  }).then(out => {
    let obj = null;

    try {
      obj = JSON.parse(out);
      console.info('OBJ', apiUrl, '=>', elapsedTime(mark));
      console.debug(obj);
    } catch (e) {
      // throw new Error(out);
      // Not every rest api returns valid JSON -- so just return the text?
      console.info('OUT', apiUrl, '=>', elapsedTime(mark));
      console.debug(out);
    }

    return obj || out;
  }).catch(err => {
    console.warn('ERR', apiUrl, '=>', elapsedTime(mark));
    console.error(err); // eslint-disable-next-line no-alert

    alert(JSON.stringify(err, null, 2));
    return err;
  });
  return result;
}

function elapsedTime(start) {
  let elapsed = Date.now() - start;
  let pieces = new Date(elapsed).toJSON().split(/[TZ]/)[1].split(':');
  return `${+pieces[1]}m ${+pieces[2]}s`;
}