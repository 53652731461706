import _implicitStylesheets from "./masterGroup.css";

import {registerTemplate} from "lwc";
function tmpl($api, $cmp, $slotset, $ctx) {
  const {k: api_key, d: api_dynamic, t: api_text, h: api_element, b: api_bind, i: api_iterator, f: api_flatten} = $api;
  const {_m0, _m1} = $ctx;
  return api_flatten([api_iterator($cmp.initializeFields, function (field) {
    return api_element("div", {
      classMap: {
        "form-group": true
      },
      key: api_key(0, field.key)
    }, [api_element("label", {
      key: 1
    }, [api_dynamic(field.label), api_text(":")]), field.readonly ? api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "data-fld-name": field.key,
        "type": field.type,
        "placeholder": field.default,
        "readonly": ""
      },
      props: {
        "value": field.value
      },
      key: 2
    }, []) : null, !field.readonly ? api_element("input", {
      classMap: {
        "form-control": true
      },
      attrs: {
        "data-fld-name": field.key,
        "type": field.type,
        "placeholder": field.default
      },
      props: {
        "value": field.value
      },
      key: 3,
      on: {
        "input": _m0 || ($ctx._m0 = api_bind($cmp.handleKeypress)),
        "change": _m1 || ($ctx._m1 = api_bind($cmp.handleChange))
      }
    }, []) : null]);
  }), $cmp.masterGroup ? api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 4
  }, [api_text("In order to use this product, an integration user must be specified for the environment."), api_element("br", {
    key: 5
  }, []), api_text("This user has already been registered. Please click "), api_element("b", {
    key: 6
  }, [api_text("Next")]), api_text(" to proceed.")]) : null, !$cmp.masterGroup ? api_element("span", {
    classMap: {
      "confirmation": true
    },
    key: 7
  }, [api_text("In order to use this product, an integration user must be specified for the environment."), api_element("br", {
    key: 8
  }, []), api_text("To complete the registration, enter this user's password and click "), api_element("b", {
    key: 9
  }, [api_text("Next")]), api_text(".")]) : null]);
}
export default registerTemplate(tmpl);
tmpl.stylesheets = [];


if (_implicitStylesheets) {
  tmpl.stylesheets.push.apply(tmpl.stylesheets, _implicitStylesheets)
}
tmpl.stylesheetTokens = {
  hostAttribute: "my-modules-m2o_masterGroup-host",
  shadowAttribute: "my-modules-m2o_masterGroup"
};
